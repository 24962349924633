import Header from "../navigation/Header";
import Footer from "../navigation/Footer";
interface Props {
  children: React.ReactNode;
}
export default function PageLayoutLanding({
  children
}: Props) {
  return <main className="scroll-smooth bg-blackln" data-aos-mirror="true" data-sentry-component="PageLayoutLanding" data-sentry-source-file="PageLayoutLanding.tsx">
            <Header data-sentry-element="Header" data-sentry-source-file="PageLayoutLanding.tsx" />
            <div>{children}</div>
            <Footer data-sentry-element="Footer" data-sentry-source-file="PageLayoutLanding.tsx" />
        </main>;
}