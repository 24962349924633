"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import image404 from "@public/img/errors/404.png";
import Link from "next/link";
import PageLayoutLanding from "@/components/landing/layout/PageLayoutLanding";
export default function NotFoundE() {
  return <PageLayoutLanding data-sentry-element="PageLayoutLanding" data-sentry-component="NotFoundE" data-sentry-source-file="not-found.tsx">
      <section className="relative bg-about bg-opacity-80 bg-blend-soft-light bg-center bg-no-repeat bg-cover">
        <div className="h-screen grid place-items-center relative text-slate-200">
          <div className="grid place-items-center py-24 lg:py-40 px-4 lg:px-0">
            <Image src={image404} alt="404" width={400} height={400} data-sentry-element="Image" data-sentry-source-file="not-found.tsx" />
            <p className="text-2xl text-center text-slate-200 pt-6 pb-10">Whoops.. Not Found!</p>
            <div className="pb-12 flex flex-col lg:flex-row justify-center gap-4 w-full lg:w-auto">
              {/* <button className="border rounded-md border-gray-800 text-gray-800 px-8 py-4 w-full lg:w-auto hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50">Contact us</button> */}
              <Link href="/" className="border rounded-md bg-gray-800 text-white px-8 py-4 w-full lg:w-auto hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50" data-sentry-element="Link" data-sentry-source-file="not-found.tsx">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </PageLayoutLanding>;
}